import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	itemSettings: {
		id: 'spa-apps-sidebar-common.notifications-sub-menu.item-settings',
		defaultMessage: 'Settings',
		description: 'The text is shown under the Notifications menu as a submenu link item.',
	},
	itemProjectEmailAuditLogs: {
		id: 'spa-apps-sidebar-common.notifications-sub-menu.item-project-email-audit-logs',
		defaultMessage: 'Project email audit',
		description: 'The text is shown under the Notifications menu as a submenu link item.',
	},
	itemIssueLogs: {
		id: 'spa-apps-sidebar-common.notifications-sub-menu.item-issue-logs',
		defaultMessage: 'Issue email audit',
		description: 'The text is shown under the Notifications menu as a submenu link item.',
	},
	newLozenge: {
		id: 'spa-apps-sidebar-common.notifications-sub-menu.new-lozenge',
		defaultMessage: 'New',
		description: 'The text is shown to indicate new feature.',
	},
	itemIssueLogsIssueTermRefresh: {
		id: 'spa-apps-sidebar-common.notifications-sub-menu.item-issue-logs-issue-term-refresh',
		defaultMessage: 'Issue email audit',
		description: 'The text is shown under the Notifications menu as a submenu link item.',
	},
});
