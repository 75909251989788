// @generated <<SignedSource::*O*zOeWoEQle#+L!plEphiEmie@IsG>>
import { ff } from '@atlassian/jira-feature-flagging';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	get(): boolean {
		return (
			ff('relay-migration-issue-fields-multi-user') ||
			ff('relay-migration-issue-fields-participants')
		);
	},
};
