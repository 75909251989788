// eslint-disable-next-line jira/import-whitelist
import { JiraProjectAri } from '@atlassian/ari/jira';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import { NAVIGATION_ITEMS_PAGE_SIZE } from '@atlassian/jira-navigation-kit-common';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import QUERY, {
	type projectNavigation_horizontalNavigationQuery as BusinessHorizontalNavQuery,
} from '@atlassian/jira-relay/src/__generated__/projectNavigation_horizontalNavigationQuery.graphql';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';

const RESOURCE_TYPE_KEY = 'RESOURCE_TYPE_BUSINESS_HORIZONTAL_NAVIGATION';
export const navigationItemsResource = createRelayResource<BusinessHorizontalNavQuery>({
	type: RESOURCE_TYPE_KEY,
	getQuery: ({ match, query }, { tenantContext }) => {
		const { projectKey, projectId } = getProjectKeyId(match, query);
		const { cloudId } = tenantContext;
		const projectIdOrKey = projectKey || projectId;
		const scopeId = projectId
			? JiraProjectAri.create({
					siteId: cloudId,
					projectId,
				}).toString()
			: '';

		return {
			parameters: QUERY,
			variables: {
				cloudId,
				projectIdOrKey,
				first: NAVIGATION_ITEMS_PAGE_SIZE,
				containerNavigationInput: scopeId
					? { scopeId }
					: { projectKeyQuery: { cloudId, projectKey } },
				useContainerNavigation: fg('custom_project_navigation_m2'),
				useNewProjectHeader: getWillShowNav4() && fg('common_project_actions_menu'),
				projectKey,
				projectAri: scopeId,
				useProjectKey: Boolean(projectKey),
			},
			options: { fetchPolicy: 'store-and-network' },
		};
	},
});
