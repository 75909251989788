/**
 * @generated SignedSource<<16983b23037882a602971fe8fd745f8c>>
 * @relayHash fb86784a7920e5e616511999de47e2aa
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b966803d671a9b1c48f111ca9b7512770e6f66722441a79f26e5e6658a257f2d

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraAtlassianIntelligenceFeatureEnum = "AI_MATE" | "NATURAL_LANGUAGE_TO_JQL" | "%future added value";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type main_IssueNavigatorQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns: number;
  atlassianIntelligenceProductFeature: JiraAtlassianIntelligenceFeatureEnum;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  issueSearchInput: JiraIssueSearchInput;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  queryStatusForIssuekeyStrikethrough: boolean;
  shouldQueryFieldSetsById: boolean;
  viewId?: string | null | undefined;
};
export type main_IssueNavigatorQuery$data = {
  readonly jira: {
    readonly userPreferences: {
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_userPreferences">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_jira">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueQuery">;
};
export type main_IssueNavigatorQuery = {
  response: main_IssueNavigatorQuery$data;
  variables: main_IssueNavigatorQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider": isChildIssueTotalCountEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
});

import isChildIssueTotalCountEnabled_provider from '@atlassian/jira-relay-provider/src/is-child-issues-total-count-enabled.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "atlassianIntelligenceProductFeature"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryStatusForIssuekeyStrikethrough"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v15 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v16 = [
  (v15/*: any*/)
],
v17 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v18 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "feature",
        "variableName": "atlassianIntelligenceProductFeature"
      }
    ],
    "kind": "ObjectValue",
    "name": "atlassianIntelligenceProductFeatureInput"
  },
  (v15/*: any*/)
],
v19 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v20 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  (v15/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "options",
    "variableName": "options"
  }
],
v21 = {
  "kind": "ScalarField",
  "name": "key"
},
v22 = {
  "kind": "ScalarField",
  "name": "id"
},
v23 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v24 = {
  "kind": "Variable",
  "name": "filterId",
  "variableName": "filterId"
},
v25 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v26 = {
  "kind": "Variable",
  "name": "namespace",
  "variableName": "namespace"
},
v27 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v28 = [
  (v24/*: any*/),
  (v25/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/)
],
v29 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v30 = {
  "kind": "ScalarField",
  "name": "name"
},
v31 = {
  "kind": "ScalarField",
  "name": "text"
},
v32 = {
  "kind": "ScalarField",
  "name": "picture"
},
v33 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v34 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v35 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v34/*: any*/),
    (v22/*: any*/)
  ]
},
v36 = {
  "args": (v28/*: any*/),
  "concreteType": "JiraIssueFieldSetConnection",
  "kind": "LinkedField",
  "name": "fieldSetsForIssueSearchView",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueFieldSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueFieldConnection",
              "kind": "LinkedField",
              "name": "fields",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v19/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v29/*: any*/),
                            (v30/*: any*/),
                            (v31/*: any*/)
                          ],
                          "type": "JiraSingleLineTextField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v29/*: any*/),
                            (v30/*: any*/),
                            {
                              "kind": "LinkedField",
                              "name": "user",
                              "plural": false,
                              "selections": [
                                (v19/*: any*/),
                                (v30/*: any*/),
                                (v32/*: any*/),
                                (v22/*: any*/)
                              ]
                            }
                          ],
                          "type": "JiraSingleSelectUserPickerField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v29/*: any*/),
                            {
                              "concreteType": "JiraIssueType",
                              "kind": "LinkedField",
                              "name": "issueType",
                              "plural": false,
                              "selections": [
                                (v30/*: any*/),
                                (v33/*: any*/),
                                (v22/*: any*/)
                              ]
                            }
                          ],
                          "type": "JiraIssueTypeField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v29/*: any*/),
                            (v35/*: any*/)
                          ],
                          "type": "JiraStatusCategoryField"
                        },
                        (v22/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v37 = {
  "condition": "queryStatusForIssuekeyStrikethrough",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isResolved"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "ids",
          "value": [
            "status"
          ]
        }
      ],
      "concreteType": "JiraIssueFieldConnection",
      "kind": "LinkedField",
      "name": "fieldsById",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v19/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v29/*: any*/),
                    {
                      "concreteType": "JiraStatus",
                      "kind": "LinkedField",
                      "name": "status",
                      "plural": false,
                      "selections": [
                        (v35/*: any*/),
                        (v22/*: any*/)
                      ]
                    }
                  ],
                  "type": "JiraStatusField"
                },
                (v22/*: any*/)
              ]
            }
          ]
        }
      ],
      "storageKey": "fieldsById(ids:[\"status\"])"
    }
  ]
},
v38 = {
  "kind": "ScalarField",
  "name": "type"
},
v39 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v40 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v41 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v30/*: any*/),
                (v22/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v42 = {
  "kind": "ScalarField",
  "name": "message"
},
v43 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v42/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v44 = [
  (v30/*: any*/),
  (v22/*: any*/)
],
v45 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v44/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v46 = {
  "kind": "ScalarField",
  "name": "date"
},
v47 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v48 = {
  "kind": "ScalarField",
  "name": "lazyIsEditableInIssueView"
},
v49 = {
  "kind": "ClientExtension",
  "selections": [
    (v48/*: any*/)
  ]
},
v50 = {
  "kind": "InlineFragment",
  "selections": [
    (v46/*: any*/),
    (v30/*: any*/),
    (v47/*: any*/),
    (v49/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v51 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
},
v52 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v21/*: any*/),
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v53 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v54 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatusCategory",
      "kind": "LinkedField",
      "name": "statusCategory",
      "plural": false,
      "selections": [
        (v30/*: any*/),
        (v34/*: any*/),
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusCategoryField"
},
v55 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v30/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v35/*: any*/),
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v56 = [
  (v30/*: any*/)
],
v57 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v58 = {
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "JiraLabelConnection",
  "kind": "LinkedField",
  "name": "selectedLabelsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraLabelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraLabel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v56/*: any*/)
        }
      ]
    },
    (v57/*: any*/)
  ],
  "storageKey": "selectedLabelsConnection(first:1000)"
},
v59 = {
  "kind": "InlineFragment",
  "selections": [
    (v58/*: any*/),
    (v30/*: any*/),
    (v49/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v60 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v33/*: any*/),
        (v30/*: any*/),
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraIssueTypeField"
},
v61 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v62 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v21/*: any*/),
        {
          "concreteType": "JiraSingleLineTextField",
          "kind": "LinkedField",
          "name": "summaryField",
          "plural": false,
          "selections": [
            (v31/*: any*/),
            (v22/*: any*/)
          ]
        },
        (v22/*: any*/),
        {
          "concreteType": "JiraColorField",
          "kind": "LinkedField",
          "name": "issueColorField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraColor",
              "kind": "LinkedField",
              "name": "color",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "colorKey"
                },
                (v22/*: any*/)
              ]
            },
            (v22/*: any*/)
          ]
        },
        {
          "concreteType": "JiraIssueTypeField",
          "kind": "LinkedField",
          "name": "issueTypeField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "issueType",
              "plural": false,
              "selections": [
                (v61/*: any*/),
                (v30/*: any*/),
                (v22/*: any*/)
              ]
            },
            (v22/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v63 = {
  "concreteType": "JiraPriority",
  "kind": "LinkedField",
  "name": "priority",
  "plural": false,
  "selections": [
    (v30/*: any*/),
    {
      "kind": "ScalarField",
      "name": "iconUrl"
    },
    (v22/*: any*/)
  ]
},
v64 = {
  "kind": "InlineFragment",
  "selections": [
    (v63/*: any*/),
    (v30/*: any*/),
    (v49/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v65 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v30/*: any*/),
        (v21/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v66 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v44/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v67 = {
  "concreteType": "JiraSprintConnection",
  "kind": "LinkedField",
  "name": "selectedSprintsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraSprintEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraSprint",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v22/*: any*/),
            {
              "kind": "ScalarField",
              "name": "sprintId"
            },
            (v30/*: any*/),
            {
              "kind": "ScalarField",
              "name": "state"
            },
            {
              "kind": "ScalarField",
              "name": "endDate"
            }
          ]
        }
      ]
    }
  ]
},
v68 = {
  "kind": "InlineFragment",
  "selections": [
    (v67/*: any*/),
    (v30/*: any*/),
    (v49/*: any*/)
  ],
  "type": "JiraSprintField"
},
v69 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v70 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v71 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v19/*: any*/),
    (v69/*: any*/),
    (v32/*: any*/),
    (v30/*: any*/),
    (v22/*: any*/),
    (v70/*: any*/)
  ]
},
v72 = {
  "kind": "InlineFragment",
  "selections": [
    (v30/*: any*/),
    {
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v44/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v73 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v74 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v30/*: any*/),
        (v61/*: any*/),
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v75 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v76 = [
  (v57/*: any*/)
],
v77 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                (v75/*: any*/)
              ]
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v76/*: any*/)
        },
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "webUrl"
                    },
                    (v21/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v19/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v35/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v22/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v22/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v22/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueLinkField"
},
v78 = {
  "kind": "ScalarField",
  "name": "isStoryPointField"
},
v79 = {
  "kind": "ScalarField",
  "name": "number"
},
v80 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v78/*: any*/),
        (v79/*: any*/),
        (v30/*: any*/),
        (v49/*: any*/)
      ],
      "type": "JiraNumberField"
    }
  ]
},
v81 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fieldSetIds",
          "variableName": "fieldSetIds"
        },
        (v25/*: any*/)
      ],
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsById",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v38/*: any*/),
                (v39/*: any*/),
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v19/*: any*/),
                            (v29/*: any*/),
                            (v38/*: any*/),
                            (v40/*: any*/),
                            (v22/*: any*/),
                            (v41/*: any*/),
                            (v43/*: any*/),
                            (v45/*: any*/),
                            (v50/*: any*/),
                            (v51/*: any*/),
                            (v52/*: any*/),
                            (v53/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v31/*: any*/),
                                (v30/*: any*/),
                                (v49/*: any*/)
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            (v54/*: any*/),
                            (v55/*: any*/),
                            (v59/*: any*/),
                            (v60/*: any*/),
                            (v62/*: any*/),
                            (v64/*: any*/),
                            (v65/*: any*/),
                            (v66/*: any*/),
                            (v68/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v71/*: any*/),
                                (v30/*: any*/),
                                (v47/*: any*/),
                                (v49/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            (v72/*: any*/),
                            (v73/*: any*/),
                            (v74/*: any*/),
                            (v77/*: any*/),
                            (v80/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v82 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "args": (v28/*: any*/),
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsForIssueSearchView",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v38/*: any*/),
                (v39/*: any*/),
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v29/*: any*/),
                            (v38/*: any*/),
                            (v40/*: any*/),
                            (v41/*: any*/),
                            (v43/*: any*/),
                            (v45/*: any*/),
                            (v50/*: any*/),
                            (v51/*: any*/),
                            (v52/*: any*/),
                            (v53/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v38/*: any*/),
                                (v49/*: any*/)
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "concreteType": "JiraStatusCategory",
                                  "kind": "LinkedField",
                                  "name": "statusCategory",
                                  "plural": false,
                                  "selections": (v56/*: any*/)
                                }
                              ],
                              "type": "JiraStatusCategoryField"
                            },
                            (v55/*: any*/),
                            (v59/*: any*/),
                            (v62/*: any*/),
                            (v64/*: any*/),
                            (v65/*: any*/),
                            (v66/*: any*/),
                            (v68/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v38/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    (v69/*: any*/),
                                    (v70/*: any*/)
                                  ]
                                },
                                (v47/*: any*/),
                                (v49/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            (v72/*: any*/),
                            (v73/*: any*/),
                            (v74/*: any*/),
                            (v77/*: any*/),
                            (v80/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v83 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    (v81/*: any*/),
    (v82/*: any*/)
  ]
},
v84 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v81/*: any*/),
    (v82/*: any*/),
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetConnection",
          "kind": "LinkedField",
          "name": "issueRowFieldSets",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSetEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldSet",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v38/*: any*/),
                    (v39/*: any*/),
                    {
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fields",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v19/*: any*/),
                                (v29/*: any*/),
                                (v38/*: any*/),
                                (v40/*: any*/),
                                (v22/*: any*/),
                                (v41/*: any*/),
                                (v43/*: any*/),
                                (v45/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v46/*: any*/),
                                    (v48/*: any*/),
                                    (v30/*: any*/),
                                    (v47/*: any*/)
                                  ],
                                  "type": "JiraDatePickerField"
                                },
                                (v51/*: any*/),
                                (v52/*: any*/),
                                (v53/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v31/*: any*/),
                                    (v48/*: any*/),
                                    (v30/*: any*/)
                                  ],
                                  "type": "JiraSingleLineTextField"
                                },
                                (v54/*: any*/),
                                (v55/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v58/*: any*/),
                                    (v48/*: any*/),
                                    (v30/*: any*/)
                                  ],
                                  "type": "JiraLabelsField"
                                },
                                (v60/*: any*/),
                                (v62/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v48/*: any*/),
                                    (v63/*: any*/),
                                    (v30/*: any*/)
                                  ],
                                  "type": "JiraPriorityField"
                                },
                                (v65/*: any*/),
                                (v66/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v48/*: any*/),
                                    (v67/*: any*/),
                                    (v30/*: any*/)
                                  ],
                                  "type": "JiraSprintField"
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v71/*: any*/),
                                    (v48/*: any*/),
                                    (v30/*: any*/),
                                    (v47/*: any*/)
                                  ],
                                  "type": "JiraSingleSelectUserPickerField"
                                },
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v74/*: any*/),
                                (v77/*: any*/),
                                {
                                  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                  "kind": "Condition",
                                  "passingValue": true,
                                  "selections": [
                                    {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        (v78/*: any*/),
                                        (v79/*: any*/),
                                        (v48/*: any*/),
                                        (v30/*: any*/)
                                      ],
                                      "type": "JiraNumberField"
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v85 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "LinkedField",
      "name": "childIssues",
      "plural": false,
      "selections": [
        (v19/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "activeProjectsOnly",
                  "value": true
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 10
                }
              ],
              "concreteType": "JiraIssueConnection",
              "kind": "LinkedField",
              "name": "issues",
              "plural": false,
              "selections": (v76/*: any*/),
              "storageKey": "issues(activeProjectsOnly:true,first:10)"
            }
          ],
          "type": "JiraChildIssuesWithinLimit"
        }
      ]
    }
  ]
},
v86 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isHighlightedIssueRow"
    }
  ]
},
v87 = {
  "kind": "ScalarField",
  "name": "totalIssueSearchResultCount"
},
v88 = [
  {
    "kind": "Literal",
    "name": "maxCursors",
    "value": 7
  }
],
v89 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v90 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v91 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v92 = [
  (v91/*: any*/),
  (v89/*: any*/),
  (v90/*: any*/)
],
v93 = {
  "concreteType": "JiraPageCursor",
  "kind": "LinkedField",
  "name": "last",
  "plural": false,
  "selections": (v92/*: any*/)
},
v94 = {
  "concreteType": "JiraPageCursor",
  "kind": "LinkedField",
  "name": "first",
  "plural": false,
  "selections": [
    (v89/*: any*/),
    (v91/*: any*/),
    (v90/*: any*/)
  ]
},
v95 = {
  "kind": "ScalarField",
  "name": "firstIssuePosition"
},
v96 = {
  "kind": "ScalarField",
  "name": "lastIssuePosition"
},
v97 = {
  "kind": "LinkedField",
  "name": "issueSearchError",
  "plural": false,
  "selections": [
    (v19/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "messages"
        }
      ],
      "type": "JiraInvalidJqlError"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v42/*: any*/),
        {
          "kind": "ScalarField",
          "name": "errorType"
        }
      ],
      "type": "JiraInvalidSyntaxError"
    }
  ]
},
v98 = {
  "kind": "ScalarField",
  "name": "isCappingIssueSearchResult"
},
v99 = [
  (v15/*: any*/),
  (v24/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/)
],
v100 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueSearchViewResult"
},
v101 = {
  "kind": "ScalarField",
  "name": "viewId"
},
v102 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "fieldSetSelectedState": "SELECTED"
    }
  },
  (v25/*: any*/)
],
v103 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v104 = {
  "concreteType": "JiraIssueSearchFieldSetEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "concreteType": "JiraIssueSearchFieldSet",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v39/*: any*/),
        (v38/*: any*/),
        (v103/*: any*/),
        {
          "kind": "ScalarField",
          "name": "jqlTerm"
        },
        {
          "kind": "ScalarField",
          "name": "isSortable"
        },
        {
          "concreteType": "JiraFieldSetPreferences",
          "kind": "LinkedField",
          "name": "fieldSetPreferences",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "width"
            }
          ]
        },
        {
          "concreteType": "JiraFieldType",
          "kind": "LinkedField",
          "name": "fieldType",
          "plural": false,
          "selections": [
            (v103/*: any*/)
          ]
        }
      ]
    }
  ]
},
v105 = {
  "kind": "ScalarField",
  "name": "hasDefaultFieldSets"
},
v106 = {
  "kind": "InlineFragment",
  "selections": [
    (v42/*: any*/),
    {
      "kind": "LinkedField",
      "name": "extensions",
      "plural": true,
      "selections": [
        (v19/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusCode"
        }
      ]
    }
  ],
  "type": "QueryError"
},
v107 = {
  "kind": "InlineFragment",
  "selections": [
    (v22/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "kind": "Fragment",
    "name": "main_IssueNavigatorQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_issueQuery"
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v16/*: any*/),
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueNavigator_userPreferences"
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "main_issueNavigator_IssueNavigator_jira"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v10/*: any*/),
      (v14/*: any*/),
      (v11/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v13/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v12/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "main_IssueNavigatorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v16/*: any*/),
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "jqlBuilderSearchMode"
              },
              {
                "kind": "ScalarField",
                "name": "isNaturalLanguageSpotlightTourEnabled"
              },
              (v17/*: any*/)
            ]
          },
          {
            "args": (v18/*: any*/),
            "kind": "ScalarField",
            "name": "shouldShowAtlassianIntelligence"
          },
          {
            "args": (v18/*: any*/),
            "kind": "LinkedField",
            "name": "atlassianIntelligenceAction",
            "plural": false,
            "selections": [
              (v19/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "isAccessible"
                  }
                ],
                "type": "JiraAccessAtlassianIntelligenceFeature"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "link"
                  }
                ],
                "type": "JiraEnableAtlassianIntelligenceDeepLink"
              }
            ]
          }
        ]
      },
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v20/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issueSearchStable",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v36/*: any*/),
                          (v37/*: any*/),
                          (v83/*: any*/),
                          (v84/*: any*/),
                          (v85/*: any*/),
                          (v86/*: any*/)
                        ]
                      },
                      (v19/*: any*/),
                      (v17/*: any*/)
                    ]
                  },
                  (v87/*: any*/),
                  {
                    "args": (v88/*: any*/),
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "around",
                        "plural": true,
                        "selections": [
                          (v89/*: any*/),
                          (v90/*: any*/),
                          (v91/*: any*/)
                        ]
                      },
                      (v93/*: any*/),
                      (v94/*: any*/)
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                  },
                  {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                      (v95/*: any*/),
                      (v96/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "firstIssueKeyFromNextPage"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "lastIssueKeyFromPreviousPage"
                      }
                    ]
                  },
                  (v97/*: any*/),
                  (v98/*: any*/),
                  (v17/*: any*/)
                ]
              },
              {
                "args": (v99/*: any*/),
                "kind": "LinkedField",
                "name": "issueSearchViewResult",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  (v100/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v101/*: any*/),
                      {
                        "args": (v102/*: any*/),
                        "concreteType": "JiraIssueSearchFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSets",
                        "plural": false,
                        "selections": [
                          (v104/*: any*/),
                          (v57/*: any*/),
                          (v17/*: any*/)
                        ]
                      },
                      (v22/*: any*/),
                      (v105/*: any*/)
                    ],
                    "type": "JiraIssueSearchView"
                  },
                  (v106/*: any*/),
                  (v107/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v20/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issueSearch",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v89/*: any*/),
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v22/*: any*/),
                          (v21/*: any*/),
                          (v23/*: any*/),
                          (v36/*: any*/),
                          (v37/*: any*/),
                          (v83/*: any*/),
                          (v84/*: any*/),
                          (v85/*: any*/),
                          (v86/*: any*/)
                        ]
                      },
                      (v19/*: any*/)
                    ]
                  },
                  {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                      (v95/*: any*/),
                      (v96/*: any*/)
                    ]
                  },
                  {
                    "args": (v88/*: any*/),
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "around",
                        "plural": true,
                        "selections": (v92/*: any*/)
                      },
                      (v93/*: any*/),
                      (v94/*: any*/)
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                  },
                  (v87/*: any*/),
                  (v97/*: any*/),
                  (v98/*: any*/),
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      (v75/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "hasPreviousPage"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "startCursor"
                      }
                    ]
                  },
                  (v17/*: any*/)
                ]
              },
              {
                "args": (v20/*: any*/),
                "filters": [
                  "cloudId",
                  "issueSearchInput",
                  "options"
                ],
                "handle": "connection",
                "key": "IssueNavigatorIssueSearchPagination__issueSearch",
                "kind": "LinkedHandle",
                "name": "issueSearch"
              },
              {
                "args": (v99/*: any*/),
                "kind": "LinkedField",
                "name": "issueSearchViewResult",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  (v100/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v101/*: any*/),
                      (v22/*: any*/),
                      (v105/*: any*/),
                      {
                        "args": (v102/*: any*/),
                        "concreteType": "JiraIssueSearchFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSets",
                        "plural": false,
                        "selections": [
                          (v57/*: any*/),
                          (v104/*: any*/),
                          (v17/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraIssueSearchView"
                  },
                  (v106/*: any*/),
                  (v107/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b966803d671a9b1c48f111ca9b7512770e6f66722441a79f26e5e6658a257f2d",
    "metadata": {},
    "name": "main_IssueNavigatorQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider": isChildIssueTotalCountEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider
    }
  }
};
})();

(node as any).hash = "ac2645746e8a36ff02277b3aac6b42cd";

export default node;
