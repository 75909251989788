/**
 * @generated SignedSource<<6ed198248e7a2f8c1064c8c2bdfdc72b>>
 * @relayHash 9a787809eab9d8fde1269fed365db42b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 66b85b9a3472d6567a7e01af55f605d068c69092ec922f513289271ac2963505

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiVersionDetailsQuery } from './uiVersionDetailsQuery.graphql';

import shouldSkipReleaseNoteField_provider from '@atlassian/jira-relay-provider/src/version-details-should-skip-release-note-field.relayprovider';

const node: PreloadableConcreteRequest<uiVersionDetailsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "66b85b9a3472d6567a7e01af55f605d068c69092ec922f513289271ac2963505",
    "metadata": {},
    "name": "uiVersionDetailsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcversiondetailsshouldskipreleasenotefieldrelayprovider": shouldSkipReleaseNoteField_provider
    }
  }
};

export default node;
