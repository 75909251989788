import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { createEntry } from '@atlassian/jira-route-entry';
import { createLayout } from '@atlassian/jira-route-layout';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { softwarePlanIncrementEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/ui/embed';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type PlanIncrementType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-increment';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanIncrementEmbed = lazyForPaint<typeof PlanIncrementType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-increment-embed", jiraSpaEntry: "async-plan-increment-embed" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-increment'
			),
		),
	{ ssr: false },
);

export const PlanIncrementEmbedPage = () => (
	<LazyPage Page={LazyPlanIncrementEmbed} pageId="plan-increment-embed" shouldShowSpinner />
);

export const arjIncrementEmbedRouteEntry: Route = createEntry(softwarePlanIncrementEmbedRoute, {
	component: componentWithFG('smart_links_for_plans', PlanIncrementEmbedPage, ErrorPagesNotFound),

	layout: createLayout({
		isChromeless: true,
	}),

	resources: [
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
	],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	forPaint: [LazyPlanIncrementEmbed],
});
