import { GlobalReactLooselyLazyProfiler } from 'react-loosely-lazy';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { retrieveAndSetPageLoadTrace } from '@atlassian/jira-retrieve-and-set-page-load-trace';
import { getJiraCustomData } from '@atlassian/jira-ufo-jira-additional-payload';
import { configure as configureBundleEvalTimings } from '@atlassian/react-ufo/bundle-eval-timing';
import {
	ModuleLoadingProfiler,
	type InteractionMetrics,
} from '@atlassian/react-ufo/interaction-metrics';
import { init as platformInit } from '@atlassian/react-ufo/interaction-metrics-init';
import {
	configure as configureResourceTimings,
	startResourceTimingBuffer,
} from '@atlassian/react-ufo/resource-timing';
import { configure as configureSsrTiming } from '@atlassian/react-ufo/ssr';
import { jiraBundleEvalTimingsConfig } from './common/utils/jira-bundle-eval-config';
import { jiraResourceTimingsConfig } from './common/utils/jira-resource-config';
import { jiraSsrConfig } from './common/utils/jira-ssr-config';

type Capability = 'feature_flag_access' | 'react_profiler';
type InteractionKind = 'page_load' | 'transition' | 'press' | 'typing' | 'legacy' | 'hover';
type UFONameOverride = {
	readonly [ufoName: string]: {
		readonly [metricKey: string]: string;
	};
};
// Defensively typed, since this is directly user-editable
// and they could delete empty members
type UFOConfigFromFF = {
	readonly doNotAbortActivePressInteraction?: string[];
	readonly doNotAbortActivePressInteractionOnTransition?: string[];
	readonly awaitBM3TTI?: string[];
	readonly ufoNameOverrides?: UFONameOverride;
	readonly rates?: {
		readonly [key: string]: number;
	};
	readonly rules?: readonly {
		readonly test?: string;
		readonly rate?: number;
	}[];
	readonly killswitch?: readonly string[];
	readonly typingMethod?: string;
	readonly capability?: Record<Capability, number>;
	readonly kind?: Record<InteractionKind, number>;
	readonly autoGeneratedRate?: number;
	readonly removePageSegmentsUFOPrefixes?: boolean;
	readonly removeInteractionsUFOPrefixes?: boolean;
};

const getUFOConfigFromFF = (): UFOConfigFromFF => getFeatureFlagValue('ufo.config', {});

function getAdditionalJiraFields(interaction: InteractionMetrics) {
	try {
		return { jira: getJiraCustomData(interaction) };
	} catch (error) {
		return {};
	}
}

function loadJiraAnalyticsWebClient() {
	return import(/* webpackChunkName: "AWC" */ '@atlassian/jira-product-analytics-web-client-async');
}

export default function init() {
	const ufoConfigFromFF = getUFOConfigFromFF();

	retrieveAndSetPageLoadTrace(getMeta);

	configureResourceTimings(jiraResourceTimingsConfig);
	startResourceTimingBuffer();

	configureBundleEvalTimings(jiraBundleEvalTimingsConfig);

	configureSsrTiming(jiraSsrConfig);

	GlobalReactLooselyLazyProfiler.current = ModuleLoadingProfiler;

	const stopVCAtInteractionFinish = fg('stop_vc_at_interaction_finish');

	const enableSegmentHighlighting = fg('ufo_enable_segment_highlighting_jira');

	platformInit(loadJiraAnalyticsWebClient(), {
		...ufoConfigFromFF,
		enableSegmentHighlighting,
		product: 'jira',
		region: getMeta('ajs-region') || 'unknown',
		additionalPayloadData: getAdditionalJiraFields,
		captureLateReRenders: fg('ufo_capture_late_rerenders_jira'),
		vc: {
			enabled: ff('uip.vc.partial', false),
			heatmapSize: getFeatureFlagValue<number>('uip.vc.size', 200),
			oldDomUpdates: false,
			devToolsEnabled: ff('uip.vc.devtools', false),
			selectorConfig: {
				id: true,
				role: true,
				className: true,
				testId: ff('uip.vc.testid', false),
			},
			ssr: ff('uip.vc.ssr', false),
			stopVCAtInteractionFinish,
		},
		enableBetterPageVisibilityApi: ff('uip.vc.better-page-visibility', false),
	});
}
