import { fg } from '@atlassian/jira-feature-gating';
import type { RouterContext } from '@atlassian/react-resource-router';
import type { SelectedPath } from '../../types';
import { appsConverter } from './route-converters/apps';
import { assetsConverter } from './route-converters/assets';
import { dashboardsConverter } from './route-converters/dashboards';
import { filtersConverter } from './route-converters/filters';
import { operationsConverter } from './route-converters/operations';
import { topLevelConverter } from './route-converters/others';
import { overviewsConverter } from './route-converters/overviews';
import { plansConverter } from './route-converters/plans';
import { projectsConverter } from './route-converters/projects';
import { roadmapsConverter } from './route-converters/roadmaps';
import { serviceManagementProjectSettingsConverter } from './route-converters/service-management-project-settings';

/**
 * This function returns the path to the selected __menu item__ in the sidebar.
 * The sidebar has a tree structure. Knowing the routeContext and the sidebar's
 * component hierarchy, this function can determine what nodes in the sidebar
 * tree should be "selected" (if the route contains enough information in the URL).
 *
 * Each child-function (e.g. getFilters()) is responsible for determining the
 * selected path for a set of route names. Sometimes a route name, on it's own,
 * is not enough to determine which parent-path should be selected.
 *
 * However, to keep the overall logic simple, a route-name SHOULD APPEAR IN ONLY
 * ONE Set(). __It then becomes the responsibility for the child-function to handle
 * all the possible selected-paths that could occur in response to the route name.__
 *
 * To help ensure that the sets contain non-overlapping route names, the unit test will
 * check this.
 */
export function routeToSelectedPath(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;

	if (topLevelConverter.routes.has(routeName)) {
		return topLevelConverter.convert(routeContext);
	}

	if (appsConverter.routes.has(routeName)) {
		return appsConverter.convert(routeContext);
	}

	if (assetsConverter.routes.has(routeName)) {
		return assetsConverter.convert(routeContext);
	}

	if (dashboardsConverter.routes.has(routeName)) {
		return dashboardsConverter.convert(routeContext);
	}

	if (filtersConverter.routes.has(routeName)) {
		return filtersConverter.convert(routeContext);
	}

	if (operationsConverter.routes.has(routeName)) {
		return operationsConverter.convert(routeContext);
	}

	if (overviewsConverter.routes.has(routeName)) {
		return overviewsConverter.convert(routeContext);
	}

	if (overviewsConverter.routes.has(routeName)) {
		return overviewsConverter.convert(routeContext);
	}

	if (plansConverter.routes.has(routeName)) {
		return plansConverter.convert(routeContext);
	}

	if (projectsConverter.routes.has(routeName)) {
		return projectsConverter.convert(routeContext);
	}

	if (roadmapsConverter.routes.has(routeName)) {
		return roadmapsConverter.convert(routeContext);
	}

	if (fg('jira_nav4_eap_drop_1')) {
		if (serviceManagementProjectSettingsConverter.routes.has(routeName)) {
			return serviceManagementProjectSettingsConverter.convert(routeContext);
		}
	}

	return [];
}
