import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { softwareFormSubmitRoute } from '@atlassian/jira-router-routes-software-form-submit-routes';
import type { Route } from '@atlassian/react-resource-router';
import { FormSubmitPage, LazyFormSubmitView } from './ui';

export const softwareFormSubmitRouteEntry: Route = createEntry(softwareFormSubmitRoute, {
	ufoName: 'next-gen-form-submit',
	component: componentWithFG('jwm_forms_to_jsw', FormSubmitPage, ErrorPagesNotFound),
	layout: chromelessLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectDetailsResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyFormSubmitView],
});
